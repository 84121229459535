<template>
  <div class="home">
    <hk-header @logUserOut="activateLogout">
      <list-icon slot="list-icon" @click="show_properties = !show_properties"></list-icon>
    </hk-header>

    <el-main>
      <property-list :active-property="selected_property"
                     :show-properties="show_properties"
                     @selectProperty="chooseProperty"
                     @clearProperty="deSelectProperty"
                     @closeProperties="show_properties = false" />

      <div class="content">
        <div v-if="permissions_loading" class="loading">
          <loader-icon></loader-icon>
        </div>
        <div v-else>
          <enterprise-apps v-if="selected_property === null" />
          <property-apps v-else :property="selected_property" />
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import mutations from "@/store/mutation_types";
import {ListIcon, LoaderIcon} from "vue-feather-icons";
import EnterpriseApps from "@/components/EnterpriseApps";
import PropertyApps from "@/components/PropertyApps";
import axios from "axios";
import * as Security from "@/common/security_manager";
import {clearAllCookies, clearLocalStorage, hkTokenOrBearerToken} from '@/common/utils'
import HkHeader from '@/components/HkHeader'
import PropertyList from '@/components/PropertyList'
import dayjs from "dayjs";
import {setCookies} from '@/common/utils'
import LoginService from "@/views/login/login.service";
import Global from '@/common/global.service';

export default {
  name: "home",
  components: {
    ListIcon, LoaderIcon,
    EnterpriseApps, PropertyApps,
    HkHeader, PropertyList
  },
  mounted() {
    // console.log("this.$route  home",this.$route)
    let token_expiry_dayjs_ob = null;

    if (localStorage.getItem('hk_token_expiry')?.includes(':') ||
      localStorage.getItem('hk_token_expiry')?.includes('-') ||
      localStorage.getItem('hk_token_expiry')?.includes('Z') ||
      localStorage.getItem('hk_token_expiry')?.includes('T')) {
      token_expiry_dayjs_ob = dayjs(localStorage.getItem('hk_token_expiry'));
    } else {
      token_expiry_dayjs_ob = dayjs(parseInt(localStorage.getItem('hk_token_expiry')));
    }

    if (dayjs().isAfter(token_expiry_dayjs_ob)) {
      this.activateLogout();
    }

    //setCookies(false);

    LoginService.initializeRefreshTokenTrigger()

    let property = JSON.parse(localStorage.getItem("current_property"));

    if (property !== null) {
      this.selected_property = property;
    } else {
      this.selected_property = null;
    }
    this.getLocalization();
  },
  data() {
    return {
      selected_property: null,
      show_properties: false,
      permissions_loading: false,
      googleSignInParams: {
        client_id: hkConfigEnvs.VUE_APP_GOOGLE_CLIENT_ID,
        hosted_domain: "hotelkeyapp.com"
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.current.user,
      hk_info: state => state.current.hk_info,
      //google_login: state => state.current.google_login,
      sso_login: state => state.current.sso_login,
      cognito_login: state => state.current.cognito_login,
      hashed_password: state => state.current.hashed_password,
      language: state => state.current.user.language_code,
    })
  },
  methods: {
    ...mapMutations([
      mutations.SET_CURRENT_TO_NULL,
      mutations.SET_CURRENT_PROPERTY,
      mutations.SET_CURRENT_BUSINESS_DAY,
      mutations.SET_DYNAMIC_URL,
      mutations.SET_PERMISSIONS,
      mutations.RESET_CURRENT_PROPERTY,
      mutations.SET_LOCALIZATION,
    ]),
    activateLogout() {
     /* if (this.google_login) {
        this.googleLogout()
      } else {

      }*/

      this.logout()

    },
    async signOutApi() {
      let params = {}
      params = {redirect_url: this.cognito_login ? `${hkConfigEnvs.VUE_APP_LOGOUT_URL}&username=${this.user?.username}&state=HK_HOME`
                : hkConfigEnvs.VUE_APP_LOGOUT_URL }
      // const apiClient = axios.create({
      let baseURL = this.cognito_login ? `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/account/signout/cognito?username=${this.user?.username}`
                    : `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/account/signout`
        // withCredentials: false, // this is the default
       let headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'hk-token': localStorage.getItem('hk_token'),
          'hk-info': this.hk_info,
          'hk-app-id': 'HK_HOME',
          'hk-app-version': hkConfigEnvs.VUE_APP_VERSION,
          'Authorization': this.cognito_login ? localStorage.getItem('hk_token') : null
        }
        // params: params
      //  });
      if (this.cognito_login){
        if(window.location.hostname === 'localhost'){
            return axios.get(baseURL, {}, {params:params, headers: headers}).then(res=>{
            this.clearData(res.status, params.redirect_url)
          }).catch( (error) => {
              this.clearData(error.response?.status, params.redirect_url)
          });
        } else{
          /**
           * EA-27496
           */
          return axios.post(baseURL, {}, {params:params, headers: headers}).then(res=>{
            this.clearData(res.status, params.redirect_url)
          }).catch( (error) => {
            this.clearData(error.response?.status, params.redirect_url)
          });

          /**
           * end of EA-27496
           */

          this.clearData(null, params.redirect_url)
        }

      } else{
        return axios.post(baseURL, {}, {params:params, headers: headers}).then(res=>{
          this.clearData(res.status, params.redirect_url)
        }).catch( (error) => {
            this.clearData(error.response?.status, params.redirect_url)
        });
      }

    },
    logout() {
      this.signOutApi();
      // this.clearData()
    },
    googleLogout() {
      window.gapi.load("auth2", async () => {
        let initSignOut = await window.gapi.auth2.init(this.googleSignInParams);
        initSignOut.signOut().then(() => {
          this.signOutApi();
          // this.clearData()
        });
        initSignOut.disconnect();
      });
    },
    clearData(status, redirect_url) {
      // console.log('redirect_url', redirect_url)
      let cognito_login = this.cognito_login
      this[mutations.SET_CURRENT_TO_NULL]();
      clearLocalStorage();
      clearAllCookies();

      if (window.location.hostname !== 'localhost' && hkConfigEnvs.VUE_APP_LOGOUT_URL) {
         window.location.href = redirect_url
      } else {
        this.$router.push({name: cognito_login ? 'different-login' : "loginWithSlash"});
      }
    },
    getPermissions(propertyId) {
      let newToken;
      let newInfo;

      if (this.google_login || this.sso_login || this.cognito_login) {
        newToken = localStorage.getItem('hk_token');
        newInfo = this.hk_info;
      } else {
        let token = Security.getTokenWithHashedPass(
          this.user.username,
          this.hashed_password
        );

        newToken = token.token;
        newInfo = token.info;
      }

      const tokenHeader = hkTokenOrBearerToken(newToken);

      const apiClient = axios.create({
        baseURL: `${hkConfigEnvs.VUE_APP_ENTERPRISE_URL}/${hkConfigEnvs.VUE_APP_CORE_API}/${hkConfigEnvs.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/${propertyId}/permissions`,
        withCredentials: false,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...tokenHeader,
          'hk-info': newInfo,
          'hk-app-id': 'HK_HOME',
          'hk-app-version': hkConfigEnvs.VUE_APP_VERSION
        }
      });

      return apiClient.get("/");
    },
    chooseProperty(property) {
      if (!property.region?.url) {
        this.$notify.error({
          title: "Dynamic URL",
          message: "Dynamic URL from property is empty: " + property.region.url
        });
      } else {
        this[mutations.SET_DYNAMIC_URL](property.region.url);
      }

      this.permissions_loading = true;
      this.getPermissions(property.id).then(res => {
        this[mutations.SET_PERMISSIONS](res.data);
        this[mutations.SET_CURRENT_PROPERTY](property);
        setCookies(false);
        this.selected_property = property;
        localStorage.setItem(
          "current_property",
          JSON.stringify(this.selected_property)
        );



      }).catch(error => {
        if (error.response?.status === 403) {
          this.activateLogout()
        }
      }).finally(() => {
        this.permissions_loading = false;
      });
    },
    deSelectProperty() {
      this.selected_property = null;
      this[mutations.RESET_CURRENT_PROPERTY]();
      localStorage.removeItem("current_property");
      //deleteAllCookiesFromAllPaths();
      setCookies(false);
    },
    getLocalization() {
      this.permissions_loading = true;
    Global.getLocalization(this.language)
      .then(res => res.data)
      .then(localizations => {
        this.permissions_loading = false;
        this[mutations.SET_LOCALIZATION](localizations);
        let user_language = this.language.substring(0, 2);
        this.$i18n.locale = user_language;
        this.$i18n.setLocaleMessage(user_language, localizations);
      })
      .catch(err => {
        this.$notify.error({
          title: 'Error',
          message: err.response ? (err.response.data ? err.response.data.message : err.response) : err
        })
        this.permissions_loading = false;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
$blue: #46a0fc;

.home {
  height: 100vh;
  overflow: hidden;
  position: relative;

  .el-main {
    height: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    .content {
      flex: 3;
      margin: 0 1rem 1rem 1rem;
      overflow: auto;
      height: calc(100% - 1rem);

      .loading {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          animation: rotate linear 3s infinite;
        }
      }
    }
  }

  .blue-color {
    color: $blue;
  }
}

@media (max-width: 768px) and (min-width: 420px) {
  .home {
    .el-main {
      .content {
        flex: 2;
      }
    }
  }
}

@media (max-width: 420px) {
  .home {
    overflow: visible;
    height: auto;
    position: relative;

    .el-main {
      flex-direction: column;
      overflow: visible;
      height: auto;

      .content {
        height: auto;
        overflow: visible;
        margin: 20px 0 0 0;
      }
    }
  }
}
</style>
